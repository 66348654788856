import { FC, useCallback } from 'react';
import { useCreateProjectMutation } from '../api/treditionApi';
import { useAppDispatch } from '../redux/hooks';
import { replace } from 'redux-first-history';
import { getEditorRouteByStep } from './getEditorRouteByStep';
import { EditorStepKey } from './EditorStepKey';
import styles from './EditorPreflightPage.module.css';
import { useTranslation } from 'react-i18next';
import { IconButton, IconButtonSize } from '../buttons/icon/IconButton';
import { BasicButton, ButtonSize } from '../buttons/basic/BasicButton';

const gtagReportConversion = (url?: Location | (string & Location)) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    send_to: 'AW-1018070642/N3l2CJC6gcEZEPKMuuUD',
    event_callback: callback,
  });
  return false;
};

export const EditorPreflightPage: FC = () => {
  const dispatch = useAppDispatch();
  const [createProject, { isLoading }] = useCreateProjectMutation();

  const { t } = useTranslation();

  const onClickCreate = useCallback(() => {
    createProject()
      .unwrap()
      .then((projectId) => {
        gtagReportConversion();
        dispatch(replace(getEditorRouteByStep(EditorStepKey.Category, projectId)));
      })
      .catch(console.error);
  }, [createProject, dispatch]);

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src={
          'https://treditionstorage.blob.core.windows.net/pageimages/4a7c7ea9-e2fd-4a5a-a3b3-eeb527ca96d1.jpg'
        }
        alt="Tredition"
      />
      <div className={styles.right}>
        <h1 className={styles.title}>{t('BEPStartTitle')}</h1>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: t('BEPStartDescriptionNew') }}
        />
        <h2 className={styles.subtitle}>{t('BEPStartFooter1')}</h2>
        <p className={styles.text}>{t('BEPStartFooter2')}</p>

        <div className={styles.actionButtons}>
          <IconButton
            iconName={'pfeillinks-control'}
            onClick={() => window.open('https://www.tredition.com', '_self')}
            label={t('BackToHomepage')}
            className={styles.backButton}
            size={IconButtonSize.medium}
          />
          <BasicButton
            onClick={onClickCreate}
            disabled={isLoading}
            label={t('Start')}
            size={ButtonSize.wide}
            className={styles.startButton}
          />
        </div>
      </div>
    </div>
  );
};
